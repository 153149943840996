/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // 
        function navMakeFixed() {
          var body_top_margin = $('header.banner').outerHeight();
          if ($(this).scrollTop() > 0) {
            $('header.banner').addClass("nav-scrolled");
            $('.site').css('margin-top', body_top_margin);
          } else {
            $('header.banner').removeClass("nav-scrolled");
            $('.site').css('margin-top', 0);
          }
        }

        navMakeFixed();

        $(window).on('scroll', function(){
          navMakeFixed();          
        });

        /**
         * Lightbox for images, if image has attribute no-data-lightbox, lightbox will not initalized
         */
        $(function($) {
          $("a[href$='.jpg'],a[href$='.png'],a[href$='.gif']").each(function(index) {
            if ($(this).attr('no-data-lightbox')) {
              // alert('dsaibled');
              // $(this).attr('data-lightbox', '');
              return false;
            }
            if (typeof($(this).attr('data-lightbox')) === 'undefined') {
              $(this).attr('data-lightbox', 'lightbox' + index);
            }
          });
        });

        lightbox.option({
          'albumLabel': 'Zdjęcie %1 z %2'
        });
        $('#menu-main-navigation').slicknav({
          prependTo: '#menu-main-mobile',
          label: ''
        });

        $('.menu-toggler').click(function(event) {
          $('#menu-main-navigation').slicknav('toggle');
        });

        $('#lang-switcher').hover(function() {
          /* Stuff to do when the mouse enters the element */
          $('.dropdown').stop().fadeIn();
        }, function() {
          /* Stuff to do when the mouse leaves the element */
          $('.dropdown').stop().fadeOut();
        });

        $(".owl-partners").owlCarousel({
          items: 6,
          nav: false,
          dots: false,
          loop: true,
          navText: ["<span class='nav-prev-icon'></span>", "<span class='nav-next-icon'></span>"],
          autoplay:true,
          autoplayTimeout: 3000,
          autoplayHoverPause:true,
          smartSpeed: 500,
          autoplaySpeed: 1000,
          responsiveClass:true,
          mouseDrag: true,
          margin: 60,
          responsive: {
            0: {
              items: 2
            },
            768: {
              items: 6
            }
          }
        });

        var prev_infowindow =false;
        var prev_marker =false;
        var map = null;

        function add_marker( $marker, map ) {

          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
          var marker = new google.maps.Marker({
              position    : latlng,
              map         : map,
              icon: '/wp-content/themes/jamp/dist/images/map-marker.png'
          });

          map.markers.push( marker );

          if( $marker ) {
            var infowindow = new google.maps.InfoWindow({
              content     : $marker.html()
            });

            google.maps.event.addListener(marker, 'click', function() {
              // // $marker.attr('data-lat'), $marker.attr('data-lng')
              if( prev_marker ) {
                 prev_infowindow.close();
                 // prev_marker.setIcon(template_directory_uri+'/dist/images/map-marker.png');
              }

              prev_infowindow = infowindow;
              prev_marker = marker;
              infowindow.open( map, marker );
              // marker.setIcon(template_directory_uri+'/dist/images/map-marker-clicked.png');
            });
          }

        }

        function center_map( map ) {

          var bounds = new google.maps.LatLngBounds();

          $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
          });

          if( map.markers.length === 1 ) {

            map.setCenter( bounds.getCenter() );
            map.setZoom( 16 );
          } else {

            map.fitBounds( bounds );
          }
        }
            
        function new_map( $el ) {

          var $markers = $el.find('.marker');

          var args = {
            zoom        : 17,
            center      : new google.maps.LatLng(0, 0),
            zoomControl: true,
            scrollwheel: true,
            draggable: true,
            disableDoubleClickZoom: true,
            mapTypeId: 'Styled',
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          };
                      
          var styles = [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c9c9c9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ];

          var map = new google.maps.Map( $el[0], args);
          var styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled' });
          map.mapTypes.set('Styled', styledMapType);
          map.markers = [];
          
          $markers.each(function(){
            add_marker( $(this), map );                
          });
          
          center_map( map );
          
          return map;            
        }
       
        $('.google-map').each(function(){
          map = new_map( $(this) );
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(".owl-slider").owlCarousel({
          items: 1,
          nav: false,
          dots: true,
          loop: true,
          navText: ["<span class='nav-prev-icon'></span>", "<span class='nav-next-icon'></span>"],
          autoplay:true,
          autoplayTimeout: 5000,
          autoplayHoverPause:true,
          smartSpeed: 500,
          autoplaySpeed: 1000,
          responsiveClass:true,
          mouseDrag: true
        });

        $('.owl-slider').trigger('refresh.owl.carousel');

        $('.owl-slider').on('click', '.linkable', function(event) {
          event.preventDefault();
          window.location.href = $(this).data('link');          
        });

        $(".owl-realizations").owlCarousel({
          items: 6,
          nav: true,
          dots: false,
          loop: true,
          navText: ["<span class='nav-prev-icon'></span>", "<span class='nav-next-icon'></span>"],
          autoplay:true,
          autoplayTimeout: 5000,
          autoplayHoverPause:true,
          smartSpeed: 500,
          autoplaySpeed: 1000,
          responsiveClass:true,
          mouseDrag: true,
          responsive: {
            0: {
              items: 1
            },
            768: {
              items: 3
            },
            992: {
              items: 6
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_realizations_template': {
      init: function() {
        // JavaScript to be fired on the about us page
        
        $(window).on('load', function() {
          var $realization_isotope = $('#realizations-container');
          
          $realization_isotope.isotope({
            itemSelector: '.realization-item',
            layoutMode: 'fitRows'
          });
          
          $realization_isotope.isotope({ filter: '*' });

          $('.filter-button-group').on( 'click', 'a', function(event) {
            event.preventDefault();

            var filterValue = $(this).attr('data-filter');
            window.location.hash = filterValue === '*' ? 'wszystkie' : filterValue.substring(1, filterValue.length);
            if (filterValue !== '*') {
              $('#realizations-container').addClass('filtered');
            } else {
              $('#realizations-container').removeClass('filtered');
            }
            $realization_isotope.isotope({ filter: filterValue });
            $(this).siblings().removeClass('current');
            $(this).addClass('current');
          });

          if (window.location.hash) {
            var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
            // alert(hash);
            
            if (hash.length) {
              $('.filter-button[data-filter=".'+hash+'"').click();
              $('html, body').animate({
                scrollTop: $('#realizations-container').offset().top - $('header.banner').outerHeight()
              });
            }
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.